exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-for-personal-tsx": () => import("./../../../src/pages/for-personal.tsx" /* webpackChunkName: "component---src-pages-for-personal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-cleaning-tsx": () => import("./../../../src/pages/industry/cleaning.tsx" /* webpackChunkName: "component---src-pages-industry-cleaning-tsx" */),
  "component---src-pages-industry-clinic-tsx": () => import("./../../../src/pages/industry/clinic.tsx" /* webpackChunkName: "component---src-pages-industry-clinic-tsx" */),
  "component---src-pages-industry-index-tsx": () => import("./../../../src/pages/industry/index.tsx" /* webpackChunkName: "component---src-pages-industry-index-tsx" */),
  "component---src-pages-industry-restaurant-tsx": () => import("./../../../src/pages/industry/restaurant.tsx" /* webpackChunkName: "component---src-pages-industry-restaurant-tsx" */),
  "component---src-pages-industry-salon-tsx": () => import("./../../../src/pages/industry/salon.tsx" /* webpackChunkName: "component---src-pages-industry-salon-tsx" */),
  "component---src-pages-industry-training-centre-tsx": () => import("./../../../src/pages/industry/training-centre.tsx" /* webpackChunkName: "component---src-pages-industry-training-centre-tsx" */),
  "component---src-pages-modules-billing-tsx": () => import("./../../../src/pages/modules/billing.tsx" /* webpackChunkName: "component---src-pages-modules-billing-tsx" */),
  "component---src-pages-modules-booking-tsx": () => import("./../../../src/pages/modules/booking.tsx" /* webpackChunkName: "component---src-pages-modules-booking-tsx" */),
  "component---src-pages-modules-e-invoice-tsx": () => import("./../../../src/pages/modules/e-invoice.tsx" /* webpackChunkName: "component---src-pages-modules-e-invoice-tsx" */),
  "component---src-pages-modules-index-tsx": () => import("./../../../src/pages/modules/index.tsx" /* webpackChunkName: "component---src-pages-modules-index-tsx" */),
  "component---src-pages-modules-payment-tsx": () => import("./../../../src/pages/modules/payment.tsx" /* webpackChunkName: "component---src-pages-modules-payment-tsx" */),
  "component---src-pages-modules-queue-tsx": () => import("./../../../src/pages/modules/queue.tsx" /* webpackChunkName: "component---src-pages-modules-queue-tsx" */),
  "component---src-pages-modules-rewards-tsx": () => import("./../../../src/pages/modules/rewards.tsx" /* webpackChunkName: "component---src-pages-modules-rewards-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-template-markdown-template-tsx": () => import("./../../../src/template/markdown-template.tsx" /* webpackChunkName: "component---src-template-markdown-template-tsx" */)
}

